<template>
  <section class="error_404">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div
          class="
            content-wrapper
            d-flex
            align-items-center
            text-center
            error-page
            bg-primary
          "
        >
          <div class="row flex-grow">
            <div class="col-lg-7 mx-auto text-white">
              <div class="row align-items-center d-flex flex-row">
                <!-- <div class="col-lg-6 text-lg-right pr-lg-4">
                  <h1 class="display-1 mb-0">404</h1>
                </div> -->
                <div class="col-lg-12 error-page-divider text-lg-left pl-lg-4">
                  <h3>Por favor, verifique seu email</h3>
                  <p>Você está quase lá! Nós enviamos um email para</p>
                  <p>
                    <strong>{{ $store.state.auth.user.email }}</strong>
                  </p>
                  <p>
                    Apenas clique no link para completar o seu cadastro. Se não
                    encontrou, verifique sua pasta de spam.
                  </p>
                  <p>Ainda não consegue encontrar o email?</p>
                  <b-button
                    variant="success"
                    @click="requestVerification"
                    :disabled="loading"
                  >
                    <template v-if="loading">
                      <b-spinner small type="grow"></b-spinner>
                      Enviando...
                    </template>
                    <template v-else> Enviar Email Novamente </template>
                  </b-button>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 mt-xl-2">
                  <p class="text-white font-weight-medium text-center">
                    Copyright &copy;
                    <a
                      href="https://piattino.digital/"
                      target="_blank"
                      class="text-white"
                      >Piattino
                    </a>
                    2021 Todos os direitos reservados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import AuthService from "../../services/auth.service";

export default {
  name: "EmailVerificar",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    requestVerification() {
      this.loading = true;
      const promise = AuthService.emailRequestVerification();
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>